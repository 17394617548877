import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import EmailsPagesTable from "../components/emailspages/EmailsPagesTable";
import EmailsPendingPagesTable from "../components/emailspages/EmailsPendingPagesTable";
import EmailsSentPagesTable from "../components/emailspages/EmailsSentPagesTable";
import EventAuditTrail from "../components/StudentAdministration/Students/EventAuditTrail";
import { getWBSEmailsAuditTrailList, getWBSEmailsAuditTrailListFilters } from "../services/WebsiteListServer";
// This will be shown in merge-all branch

function EmailsPages() {
    const history = useHistory();
    const { tab } = useParams();

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                Emails
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab === "table" || tab === "pending" || tab === "sent" || tab === "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" title="Emails" onClick={() => history.push(`/website_management/pages/emailspages/table`)}>
                                                <Link
                                                    className={`nav-link ${tab === "table" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/table`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                    Email
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Pending" onClick={() => history.push(`/website_management/pages/emailspages/pending`)}>
                                                <Link
                                                    className={`nav-link ${tab === "pending" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/pending`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-mailbox"></i>
                                                    Pending
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Sent" onClick={() => history.push(`/website_management/pages/emailspages/sent`)}>
                                                <Link
                                                    className={`nav-link ${tab === "sent" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/sent`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-check"></i>
                                                    Sent
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Audit trail" onClick={() => history.push(`/website_management/pages/emailspages/auditTrail`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/auditTrail`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "table" && <EmailsPagesTable />}
                                            {tab == "pending" && <EmailsPendingPagesTable />}
                                            {tab == "sent" && <EmailsSentPagesTable />}
                                            {tab == "auditTrail" && <EventAuditTrail apiParams={''} apiFunction={getWBSEmailsAuditTrailList} apiFilter={getWBSEmailsAuditTrailListFilters} exportName={"Emails"} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailsPages;
