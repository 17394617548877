import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import { GetStudentDetailsCancelToken } from "../services/StudentsSettingServices";
import axios from "axios";
import StaticPagesTable from "../components/pages/staticPages/StaticPagesTable";
import { useSelector } from "react-redux";
import hasPermission from "../utils/hasMultiplePermission";
import EventAuditTrail from "../components/StudentAdministration/Students/EventAuditTrail";
import { getStaticPageAuditTrailList, getStaticPageAuditTrailListFilters } from "../services/WebsiteListServer";
// This will be shown in merge-all branch

function StaticPages() {
    const history = useHistory();
    const { type, id, tab, subType, subId } = useParams();
    const givenPermission = useSelector(state => state.permission)

    useEffect(
        () => {
            let response = hasPermission({
                scopes: ["wbsstaticpageview"],
                permissions: givenPermission,
            });
            if (!response) {
                history.push("/noaccess");
            }
        }, []
    )


    const [loading, setLoading] = useState(false);
    const [websiteDetail, setWebsiteDetail] = useState([]);

    useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
            setLoading(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                setWebsiteDetail({ first_name: "", last_name: "" });
                const res = await GetStudentDetailsCancelToken(id, source.token);
                if (res.status == 200) {
                    setWebsiteDetail(res.data.data_list);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (type == "open") {
            getData();
        }

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [type]);

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {/* Student <span>List</span> */}
                                                Static&nbsp;
                                                <span>Pages</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab === "table" || tab === "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/staticpages/table`)}>
                                                <Link
                                                    className={`nav-link ${tab === "table" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/staticpages/table`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                    title="Static Pages"
                                                >
                                                    <i className="fa fa-file"></i>
                                                    Static Pages
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/staticpages/auditTrail`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/staticpages/auditTrail`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                    title="Audit trail"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "table" && <StaticPagesTable />}
                                            {/* {tab == "auditTrail" && <AuditTrail />} */}
                                            {tab == "auditTrail" && <EventAuditTrail apiParams={''} apiFunction={getStaticPageAuditTrailList} apiFilter={getStaticPageAuditTrailListFilters} exportName={"Static pages"} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaticPages;
