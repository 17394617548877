import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import AuditTrail from "../StudentAdministration/Students/AuditTrail";
import EmailsPagesOpt from "./EmailsPagesOpt";
import { getWBSEmailDetails } from "../../services/EmailsPageServer";
import AuditTrailsTable from "../common/AuditTrailsTable";
import { getWBSEmailsAuditTrailList, getWBSEmailsAuditTrailListFilters } from "../../services/WebsiteListServer";
import { RenderStudentResourceType } from "../../utils/CommonGroupingItem";
// This will be shown in merge-all branch

function CreateEmailsPages() {
    const history = useHistory();
    const { tab, type, id, subId } = useParams();
    const [updateData, setUpdateData] = useState([]);
    const [refresh, setRefresh]=useState(Date.now())

    useEffect(() => {
        if(type =="update"){
            getWBSEmailDetails(id)
            .then(res => {
                setUpdateData(res?.data?.emailDetails)
            })
        }      
    }, [id,refresh])

    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

    const apiParams = {
        action_id: subId || id,
    }
    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {/* Student <span>List</span> */}
                                                Emails: <span>{type === "update" ? updateData?.name : "Create New"}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(type === "auditTrail" || type === "update") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/emailspages/table/update/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "update" ? "active" : null}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/table/update/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-info-circle"></i>
                                                    Email Details
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/emailspages/table/auditTrail/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/emailspages/table/auditTrail/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {type == "create" && <EmailsPagesOpt />}
                                            {type == "update" && <EmailsPagesOpt updateData={updateData} refresh={refresh} setRefresh={setRefresh} />}
                                            {type == "auditTrail" &&
                                                <AuditTrailsTable
                                                    apiFunction={getWBSEmailsAuditTrailList}
                                                    filterArrApi={getWBSEmailsAuditTrailListFilters}
                                                    columnsToShow={columnsToShow}
                                                    exportFileName={""}
                                                    isResourceNameShown={true}
                                                    apiParams={apiParams}
                                                    actionId={subId || id}
                                                    isShowingResourceTypeFilter
                                                    renderResourceTypeFunction={RenderStudentResourceType}
                                                />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateEmailsPages;
