import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../../common/Header";
import SideBar from "../../../common/SideBar";
import EventDetails from "./InfoPacksDetails";
import EventAuditTrail from "../../../StudentAdministration/Students/EventAuditTrail";
import { getEventsAuditTrailList, getEventsAuditTrailListFilters } from "../../../../services/WebsiteListServer";

function InfoPacksTabs() {
    const history = useHistory();
    const { id, tab, subTab, subId="" } = useParams();
     const [headerName, setHeaderName] = useState(() => {
        return localStorage.getItem("headerName") || "";
    });
    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div
                    className={
                        "sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")
                    }
                >
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 class="text-left-align landing-heading heading_color_dynamic">
                                                {subTab == "create" ? "Info Packs: " : "Info Packs: "}
                                                <span>{subTab == "create" ? "Create New" : headerName}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button
                                                        onClick={() => history.goBack()}
                                                        title="Back"
                                                        className="btn btn-white-bordered"
                                                    >
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(subTab == "open") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/events/info_packs/email_details/${subTab}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "email_details" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/events/info_packs/email_details/${subTab}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i class="fa fa-info-circle"></i>
                                                    Email Details
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/websiteManagement/events/info_packs/auditTrail/${subTab}/${subId}`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/events/info_packs/auditTrail/${subTab}/${subId}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "email_details" && <EventDetails setHeaderName={setHeaderName}/>}
                                            {tab == "auditTrail" && <EventAuditTrail apiParams={{ action_id: subId,resource_type:['Info Packs'] }} apiFunction={getEventsAuditTrailList} apiFilter={getEventsAuditTrailListFilters} exportName={"Event_auditTrail"}/>}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoPacksTabs;
